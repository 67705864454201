import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { EditOutlined } from '@material-ui/icons';
import {
  Box,
  Button,
  Modal,
  TablePagination,
  TextField,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterListRounded';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ExcelJs from 'exceljs';
import { columns } from './columns';
import TagsSelect from '../../../components/TagsSelect';
// import TagsInput from '../../../components/TagsInput';
import Input from '../../../components/FormInput';
import Select from '../../../components/SelectForm';
import ButtonForm from '../../../components/Button';
import Table from '../../../components/DevExpressTable/DevExpressTable';
import BackButton from '../../../components/BackButton';

import {
  ButtonEditAnswer,
  // AddNewTagButton,
  CheckReviewed,
  Container,
  Content,
  DivButtonEditAnswer,
  // DivAddNewTag,
  DivModal,
  DivTitle,
  Filter,
  FilterAndReviewed,
} from './styles';

import { IActionColumn } from '../../../components/DevExpressTable/ActionsColumn';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import AutoGrowingTextarea from '../../../components/TextAreaEb/autoGrowingTextArea';

// interface Tag {
//   tagId: number;
//   category: string;
//   category_2: string;
// }

interface ParamTypes {
  surveyId: string;
}

interface OpenAnswers {
  index?: number; // Grid needs a unique key
  resultId: number;
  questionId: number;
  questionText: string;
  answerOriginal: string;
  answerGerman: string;
  categories: string[];
  categoriesWithParent: string[];
  categoriesSplited: string;
  semantic: string;
  userGroup: string;
  languageCode: string;
}

interface State {
  pageTitle: string;
  isReviewed: boolean;
  userGroup: string;
  projectName: string;
}

interface DropDown {
  label: string;
  value: number;
}

const semanticOptions: DropDown[] = [
  { label: 'positive', value: 1 },
  { label: 'negative', value: 2 },
  { label: 'neutral', value: 3 },
];

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function splitCategories(categories: string[]): string {
  if (categories && categories.length > 0) {
    return categories.join(', ');
  }
  return '';
}

const OpenAnswers: React.FC = () => {
  const { state } = useLocation<State>();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const params = useParams<ParamTypes>();

  const [isReviewed, setIsReviewed] = useState<boolean>(false);

  const [results, setResults] = useState<OpenAnswers[]>([]);
  const [page, setPage] = React.useState(0);
  const [countTotal, setCountTotal] = React.useState(0);
  const rowsPerPage = 100;

  const [open, setOpen] = React.useState(false);
  const handleOpenModal = (): void => setOpen(true);
  const handleCloseModal = (): void => setOpen(false);

  const [selectedTags, setSelectedTags] = useState([]);
  const [newTags, setNewTags] = useState<any[]>([]);
  const [isNewTagsFieldVisible, setIsNewTagsFieldVisible] =
    useState<boolean>(false);
  const [tagsSearch, setTagsSearch] = useState<string[]>([]); // all categories from DB

  const [openAnswerEdit, setOpenAnswerEdit] = useState<any>();

  const [filterTextQuestion, setFilterTextQuestion] = useState<string>('');
  const [filterCategories, setFilterCategories] = useState<string>('');
  const [filterAnswer, setFilterAnswer] = useState<string>('');

  const [textQuestionInput, settextQuestionInput] = useState<string>('');
  const [categoryInput, setcategoriesInput] = useState<string>('');
  const [answerInput, setAnswerInput] = useState<string>('');

  const [editingAnswer, setEditingAnswer] = useState<boolean>(false);

  const [tableColumnExtensions] = useState([
    { columnName: 'userGroup', width: 0 },
    { columnName: 'resultId', width: 0 },
    { columnName: 'questionId', width: 0 },
    { columnName: 'categories', width: 0 },
    { columnName: 'languageCode', width: 0 },
    { columnName: 'categoriesSplited', width: 200 },
    { columnName: 'semantic', width: 110 },
  ]);

  const handleClickEdit = useCallback((row: any) => {
    setEditingAnswer(false);

    setOpenAnswerEdit({
      ...row,
      semantic: semanticOptions.find(x => x.label === row.semantic),
      categories: row.categories?.map((x: any) => {
        return { value: x };
      }),
      categoriesWithParent: row.categoriesWithParent?.map((x: any) => {
        return { value: x };
      }),
    });

    handleOpenModal();
  }, []);

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: 'Edit',
      onClick: handleClickEdit,
      icon: <EditOutlined />,
    },
  ]);

  const getResults = useCallback(
    (
      pageParam: number,
      surveyId: string,
      textQuestionToFilter: string,
      categoriesToFilter: string,
      answerToFilter: string,
    ) => {
      api
        .post(`/results/survey/exportTextQuestions/${surveyId}`, {
          skip: pageParam * rowsPerPage,
          limit: rowsPerPage,
          textQuestionToFilter,
          categoriesToFilter,
          answerToFilter,
        })
        .then(response => {
          if (response.data) {
            const totalResults = response.data.count ?? 0;
            setCountTotal(totalResults);
            const resultList = response.data.results ?? [];

            setResults(
              resultList.map((x: any, index: number) => {
                const answer: OpenAnswers = {
                  index,
                  languageCode: x.languageCode,
                  userGroup: x.userGroup,
                  resultId: x.resultId,
                  questionId: x.questionId,
                  questionText: x.questionText,
                  answerOriginal: x.value,
                  answerGerman: x?.metadata?.translation ?? '',
                  categories: x.metadata?.categories ?? '',
                  categoriesWithParent: x?.metadata?.categoriesWithParent ?? '',
                  semantic: x?.metadata?.semantic ?? '',
                  categoriesSplited:
                    x?.metadata?.categories != null
                      ? splitCategories(x.metadata.categories)
                      : '',
                };
                return answer;
              }),
            );
          } else {
            addToast({
              type: 'error',
              title: 'Error',
              description: 'An error occurred, please try again.',
            });
          }
        });
    },
    [addToast],
  );

  const handleSubmit = useCallback(
    (data: any) => {
      const resultId = +data.resultId;
      const questionId = +data.questionId;
      const semantic = semanticOptions.find(
        x => x.value === data.semantic,
      )?.label;

      const categories = JSON.parse(data.inputTags);
      if (semantic === undefined) {
        addToast({
          type: 'error',
          title: 'Semantic is required',
          description: 'Please select a semantic first.',
        });
      } else if (categories.length === 0) {
        addToast({
          type: 'error',
          title: 'Categories is required',
          description: 'Please select a category first.',
        });
      } else {
        const { answerEdited } = data;
        const body: any = {
          ...(editingAnswer && { answerEdited }),
          resultId,
          questionId,
          answerEdited,
          metadata: {
            original_text: data.answerOriginal,
            user_group: data.subGroup,
            language: data.languageCode,
            translated: data.translation,
            sentiment:
              semanticOptions.find(x => x.value === data.semantic)?.label ?? '',
            categories,
          },
        };

        api
          .post(`/results/updateResultMetadataManually`, body)
          .then(response => {
            if (response.data.updateMessage) {
              addToast({
                type: 'error',
                title: 'Update Metadata',
                description: response.data.updateMessage,
              });
            } else {
              const { surveyId } = params;
              getResults(
                page,
                surveyId,
                filterTextQuestion,
                filterCategories,
                filterAnswer,
              );
              addToast({
                type: 'success',
                title: 'Update Metadata',
                description: 'Answer metadata was updated successfully.',
              });
            }
          });
        if (isNewTagsFieldVisible) {
          setIsNewTagsFieldVisible(false);
          setTagsSearch(tagsSearch);
          setNewTags([]);
          setSelectedTags([]);
        }
        handleCloseModal();
      }
    },
    [
      addToast,
      editingAnswer,
      filterAnswer,
      filterCategories,
      filterTextQuestion,
      getResults,
      isNewTagsFieldVisible,
      page,
      params,
      tagsSearch,
    ],
  );

  // get isReviewed
  useEffect(() => {
    if (state) {
      setIsReviewed(state.isReviewed);
    } else {
      api.get(`surveys/${params.surveyId}`).then(response => {
        if (response.data && response.data.isApproved) {
          setIsReviewed(response.data.isApproved);
        } else {
          setIsReviewed(false);
        }
      });
    }
  }, [params.surveyId, state]);

  // get ALL categories by API
  useEffect(() => {
    let userGroupLocal = state?.userGroup;

    if (state?.projectName.includes('TSÖ')) {
      userGroupLocal = 'tso';
    } else if (
      userGroupLocal === 'Employees' ||
      userGroupLocal === 'Management'
    ) {
      userGroupLocal = 'managers';
    } else {
      userGroupLocal = 'customers';
    }

    const type = userGroupLocal;

    api.get(`/tags/listAllFormatted/${type}`).then(response => {
      if (response.data) {
        setTagsSearch(response.data);
      }
    });
  }, [state, state?.projectName, state?.userGroup]);

  // get results
  useEffect(() => {
    const { surveyId } = params;
    setResults([]);

    getResults(
      page,
      surveyId,
      filterTextQuestion,
      filterCategories,
      filterAnswer,
    );
  }, [
    addToast,
    getResults,
    page,
    params,
    filterCategories,
    filterTextQuestion,
    filterAnswer,
  ]);

  const handleChangePage = useCallback((event: any, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleLabelPage = useCallback((event: any): React.ReactNode => {
    return (
      <span>
        {event.from}-{event.to} of {event.count}
      </span>
    );
  }, []);

  const handleChangeIsReviewed = useCallback(() => {
    const { surveyId } = params;
    setIsReviewed(!isReviewed);

    try {
      api
        .put(`/surveys/${surveyId}/setApproved`, {
          isApproved: !isReviewed,
        })
        .then(async response => {
          if (response.data) {
            setIsReviewed(!isReviewed);
            addToast({
              type: 'success',
              title: 'Update Review',
              description: 'Data saved successfully',
            });
          } else {
            addToast({
              type: 'error',
              title: 'Error',
              description:
                'An error ocurred while updating reviewed, please try again.',
            });
          }
        });
    } catch (error) {
      console.log('error', error);
      addToast({
        type: 'error',
        title: 'Error',
        description:
          'An error ocurred while updating reviewed, please try again.',
      });
    }
  }, [addToast, isReviewed, params]);

  const handleClickFilter = useCallback(() => {
    setFilterTextQuestion(textQuestionInput ?? '');
    setFilterCategories(categoryInput ?? '');
    setFilterAnswer(answerInput ?? '');

    setPage(0);
  }, [answerInput, categoryInput, textQuestionInput]);

  const exportToExcel = useCallback(async () => {
    const workbook = new ExcelJs.Workbook();

    // get List textQuestions and answers to fill allDataExcel
    await api
      .get(`/results/survey/exportResultsTextQuestions/${params.surveyId}`)
      .then(response => {
        response.data.forEach((dataMock: any) => {
          const sheet = workbook.addWorksheet(dataMock.nameSheet);

          sheet.columns = [
            {
              header: 'Gruppe',
              key: 'subgroup',
              width: 60,
            },
            {
              header: 'Antwort',
              key: 'answer',
              width: 60,
            },
          ];

          dataMock.data.forEach((answer: any) => {
            sheet.addRow({
              subgroup: answer.subgroup,
              answer: answer.answer,
            });
          });

          // Insert a row by sparse Array (assign to columns A, E & I)
          const rowValues = [];
          rowValues[1] = dataMock.textQuestion;

          // insert new row and return as row object
          sheet.insertRow(1, rowValues);
          sheet.mergeCells(1, 1, 1, sheet.columns.length);

          sheet.getCell('A1').font = {
            // name: 'Comic Sans MS',
            // family: 4,
            // size: ,
            // underline: true,
            bold: true,
          };
          sheet.getCell('A1').alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };

          sheet.getCell('A2').font = {
            bold: true,
          };

          sheet.getCell('B2').font = {
            bold: true,
          };

          sheet.getCell('A2').alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };

          sheet.getCell('B2').alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
        });
      });

    await workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `Answers_survey_${params.surveyId}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }, [params.surveyId]);

  return (
    <Container>
      <BackButton />
      <h1>Open Answers</h1>
      <DivTitle>
        <h2>{state?.pageTitle ?? ''}</h2>

        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={exportToExcel}
        >
          Download
        </Button>
      </DivTitle>

      <FilterAndReviewed>
        <Filter>
          <></>
          <TextField
            id="filterTextQuestion"
            label="Question Text"
            value={textQuestionInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              settextQuestionInput(event.target.value);
            }}
          />

          <TextField
            id="filterCategories"
            label="Category"
            value={categoryInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setcategoriesInput(event.target.value);
            }}
          />

          <TextField
            id="filterAnswer"
            label="Answer"
            value={answerInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswerInput(event.target.value);
            }}
          />

          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={handleClickFilter}
          >
            {' '}
            Filter
          </Button>
        </Filter>

        <CheckReviewed>
          <input
            type="checkbox"
            checked={isReviewed}
            onChange={handleChangeIsReviewed}
          />
          <span id="labelIsLocked"> Reviewed</span>{' '}
        </CheckReviewed>
      </FilterAndReviewed>

      {openAnswerEdit && (
        <DivModal>
          <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box css={styleModal}>
              <>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <p>{openAnswerEdit.userGroup}</p>
                  <p>
                    <b>ResultId:</b> {openAnswerEdit.resultId}
                  </p>
                  <p>
                    <b>QuestionId:</b> {openAnswerEdit.questionId}
                  </p>
                  <p>
                    <b>Language:</b> {openAnswerEdit.languageCode}
                  </p>
                  <p>
                    <b>Question:</b> {openAnswerEdit.questionText}
                  </p>
                  <p>
                    <b>Answer:</b> {openAnswerEdit.answerOriginal}
                  </p>
                  <div hidden={true}>
                    <h2>ResultId</h2>
                    <Input
                      defaultValue={openAnswerEdit.resultId}
                      name="resultId"
                      readOnly={true}
                    />

                    <h2>QuestionId</h2>
                    <Input
                      defaultValue={openAnswerEdit.questionId}
                      name="questionId"
                      readOnly={true}
                    />

                    <h2>SubGroup</h2>
                    <Input
                      defaultValue={openAnswerEdit.userGroup}
                      name="subGroup"
                      readOnly={true}
                    />
                    <h2>Language</h2>
                    <Input
                      defaultValue={openAnswerEdit.languageCode}
                      name="languageCode"
                      readOnly={true}
                    />
                    <h2>Answer </h2>
                    <Input
                      defaultValue={openAnswerEdit.answerOriginal}
                      name="answerOriginal"
                      readOnly={true}
                    />
                  </div>
                  <DivButtonEditAnswer>
                    <ButtonEditAnswer
                      onClick={(e: any) => {
                        setEditingAnswer(!editingAnswer);
                        e.preventDefault();
                      }}
                    >
                      {editingAnswer ? 'Abort' : 'Edit Answer'}
                    </ButtonEditAnswer>
                  </DivButtonEditAnswer>

                  {editingAnswer && (
                    <div>
                      <h2>Answer </h2>
                      <Input
                        // aqui o valor que queremos eh o valor atual da answer, o "value"
                        defaultValue={openAnswerEdit.answerOriginal}
                        name="answerEdited"
                        placeholder="Enter the answer"
                      />
                    </div>
                  )}

                  <h2>Translation</h2>
                  {/* <Input
                    defaultValue={openAnswerEdit.answerGerman}
                    name="translation"
                    placeholder="Enter the name"
                  /> */}
                  <AutoGrowingTextarea
                    name={'translation'}
                    placeholder="Enter the name"
                    value={openAnswerEdit.answerGerman}
                  ></AutoGrowingTextarea>

                  <h2>Semantic</h2>
                  <Select
                    name="semantic"
                    defaultValue={openAnswerEdit.semantic}
                    options={semanticOptions}
                  />

                  <h2>Categories</h2>
                  <TagsSelect
                    name="tagsArray1"
                    setSelectedTags={setSelectedTags}
                    tags={openAnswerEdit?.categoriesWithParent}
                    tagsSearch={tagsSearch}
                    acceptNewValues={false}
                  ></TagsSelect>

                  <Input
                    type="text"
                    readOnly={true}
                    show={false}
                    name="inputTags"
                    value={
                      newTags.length === 0 && selectedTags.length > 0
                        ? `[${selectedTags.map(
                            (item: any) => `"${item.value}"`,
                          )}]`
                        : newTags.length > 0 && selectedTags.length === 0
                        ? `[${newTags.map(item => `"${item}"`)}]`
                        : newTags.length === 0 && selectedTags.length === 0
                        ? `[]`
                        : `[${selectedTags.map(
                            (item: any) => `"${item.value}"`,
                          )}, ${newTags.map(item => `"${item}"`)} ]`
                    }
                  />

                  <ButtonForm type="submit" width="200px">
                    Save
                  </ButtonForm>
                </Form>
              </>
            </Box>
          </Modal>
        </DivModal>
      )}
      <Content>
        <>
          <Table
            columnsProp={columns}
            dataProp={results}
            checkboxSelection={false}
            actionColumns={actionColumns}
            tableColumnExtensions={tableColumnExtensions}
            idName={'index'}
            defaultPageSize={rowsPerPage}
            hiddenFooter={true}
          ></Table>
          {results.length > 0 && (
            <TablePagination
              component="div"
              count={countTotal}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              labelDisplayedRows={handleLabelPage}
            />
          )}
        </>
      </Content>
    </Container>
  );
};

export default OpenAnswers;
