/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useParams, Link } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Lock, LockOpen } from '@material-ui/icons';

import api from '../../../services/api';

import getEncodedSurvey from '../../../utils/getEncodedSurvey';
import getDecodedSurvey from '../../../utils/getDecodedSurvey';

import Button from '../../../components/Button';
import SpinnerThreeDots from '../../../components/LoadingSpinner/SpinnerThreeDots';

import {
  Container,
  TitleLockedIcon,
  ButtonLock,
  CheckIntegrate,
} from './styles';
import { useToast } from '../../../hooks/toast';
import SortTree from '../../../components/Skeleton';
import { SurveyProvider } from '../../../components/SurveyComponent/SurveyCreatorContext/SurveyCreatorContext';
import SurveyCreatorRenderComponent from '../../../components/SurveyComponent/SurveyCreatorContext/SurveyCreatorRenderComponent';
import getSkeletonFromSurvey from '../../../utils/getSkeletonFromSurvey';
import getSurveyFromSkeleton from '../../../utils/getSurveyFromSkeleton';
import EnumProductSubGroup from '../../../utils/enums/EnumProductSubGroup';
import updateSurveyTranslations from '../../../utils/updateSurveyTranslations';
import getValidationQuestionByElementType from '../../../utils/getValidationQuestionByElementType';
import getQuestionsWithTranslations from '../../../utils/getQuestionsWithTranslations';

interface Access {
  segments: string[];
  name: string;
  accessId: number;
}

interface Page {
  elements: Element[];
}

interface Element {
  id: number;
  idSubRow: string;
  isAlternative: boolean;
  questionId: number;
  name: string;
  title: string;
  type: any;
  choices?: Choice[];
  rows?: any;
  columns?: any;
}

interface Trigger {
  type: string;
  expression: string;
  setToName?: string;
  runExpression: string;
}

interface Survey {
  idSurvey: number;
  name: string;
  title: string;
  showTitle: boolean;
  description: string;
  type?: string;
  isTemplate: boolean;
  startTextId: number;
  finalTextId: number;
  projectId: number;
  productId: number;
  logo: Logo;
  pages: Page[];
  triggers: Trigger[];
  accesses: Access[];
  subGroup: any;
  style: any;
  languages: number[];
  gdprId: number;
  isApproved?: boolean;
}

interface Choice {
  valueRate?: number;
  text: string;
}

interface Logo {
  id: number;
  filePath: string;
}

interface Skeleton {
  surveyId: string;
  skeletonTree: any;
}

const SurveyPageEdit: React.FC = () => {
  const switchRef = useRef(null);
  const switchIntegrateRef = useRef(null);
  const switchDailyIntegrationRef = useRef(null);
  const { addToast } = useToast();
  const surveyIdParam: { surveyId: string } = useParams();

  const [survey, setSurvey] = useState<Survey>();
  const [selectedTab, setSelectedTab] = useState<string>('surveyCreator');
  const [accesses, setAccesses] = useState<Access[]>([]);
  const [skeleton, setSkeleton] = useState<Skeleton>();
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [isIntegrate, setIsIntegrate] = useState<boolean>(true);
  const [isDailyIntegration, setIsDailyIntegration] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>();
  const [surveyCreatorText, setSurveyCreatorText] = useState<string>(
    JSON.stringify({
      title: '',
      isTemplate: false,
      pages: [{ name: 'page1', elements: [] }],
    }),
  );

  const loadSurveyFromDatabase = useCallback(async () => {
    await api
      .get(`/surveys/${surveyIdParam.surveyId}`, {
        params: {
          surveyId: surveyIdParam.surveyId,
        },
      })
      .then(response => {
        const jsonFromDb = {
          gdprId: response.data?.gdprId,
          idSurvey: response.data.surveyId,
          pages: response.data.pages,
          triggers: response.data.triggers,
          description: response.data.description,
          name: response.data.name,
          title: response.data.title,
          showTitle: response.data?.showTitle,
          isTemplate: response.data.isTemplate,
          logo: response.data.logo,
          subGroup: response.data.subGroup,
          projectId: response.data.projectId,
          productId: response.data.productId,
          startTextId: response.data.startTextId,
          finalTextId: response.data.finalTextId,
          accesses: response.data.accessess,
          style: response.data.style,
          languages: response.data.languages,
          isApproved: response.data.isApproved ?? false,
          ...(response.data.showTitle !== undefined
            ? {
                showTitle: response.data.showTitle,
              }
            : {}),
        };

        let strSubGroup = '';

        switch (jsonFromDb.subGroup) {
          case 'Employees':
            strSubGroup = EnumProductSubGroup.Employees;
            break;
          case 'Management':
            strSubGroup = EnumProductSubGroup.Management;
            break;
          case 'Corporate':
            strSubGroup = EnumProductSubGroup.Corporate;
            break;
          case 'Retail':
            strSubGroup = EnumProductSubGroup.Retail;
            break;
          case 'Wealthy':
            strSubGroup = EnumProductSubGroup.Wealthy;
            break;
          default:
            break;
        }

        if (jsonFromDb.isTemplate) {
          setPageTitle(`${jsonFromDb.name}`);
        } else {
          const projectNameFromDatabase = response.data.projectName;

          setPageTitle(
            `${projectNameFromDatabase} - ${jsonFromDb.name} - ${strSubGroup}`,
          );
          setProjectName(projectNameFromDatabase);
        }

        if (response.data.isLocked) {
          setIsLocked(response.data.isLocked);
        }

        // property responsible to define if survey is or not to be integrate with data warehouse
        setIsIntegrate(response.data.isIntegrate);

        const decodedSurvey = getDecodedSurvey(jsonFromDb);

        // Please, don't remove this conversion. decodedSurvey has "[[Prototype]]" field,
        // so to remove it, need to stringify it and then apply the JSON.parse
        const surveyJsonConversion = JSON.stringify(decodedSurvey);

        setSurvey(JSON.parse(surveyJsonConversion));
        // await getSkeletonFromDatabase(JSON.parse(surveyJsonConversion));
        setSurveyCreatorText(JSON.stringify(decodedSurvey));

        if (jsonFromDb) {
          api
            .get(`/accesses/survey/${jsonFromDb.idSurvey}`, {
              params: {
                surveyId: jsonFromDb.idSurvey,
              },
            })
            .then(resp => {
              setAccesses(resp.data);
            });
        }
      });
  }, [surveyIdParam.surveyId]);

  const handleSetSelectedTab = useCallback(
    async (event: ChangeEvent<any>, newValue: string): Promise<void> => {
      let localSurvey = {};
      if (newValue === 'skeleton') {
        api
          .get(`/surveys/${surveyIdParam.surveyId}`, {
            params: {
              surveyId: surveyIdParam.surveyId,
            },
          })
          .then(response => {
            const jsonFromDb = {
              idSurvey: response.data.surveyId,
              pages: response.data.pages,
              triggers: response.data.triggers,
              description: response.data.description,
              name: response.data.name,
              title: response.data.title,
              isTemplate: response.data.isTemplate,
              logo: response.data.logo,
              subGroup: response.data.subGroup,
              projectId: response.data.projectId,
              productId: response.data.productId,
              startTextId: response.data.startTextId,
              finalTextId: response.data.finalTextId,
              gdprId: response.data.gdprId,
              accesses: response.data.accessess,
              style: response.data.style,
              languages: response.data.languages,
              ...(response.data.showTitle !== undefined
                ? {
                    showTitle: response.data.showTitle,
                  }
                : {}),
            };
            const decodedSurvey = getDecodedSurvey(jsonFromDb);
            const surveyJsonConversion = JSON.stringify(decodedSurvey);
            localSurvey = JSON.parse(surveyJsonConversion);
            setSurvey(JSON.parse(surveyJsonConversion));
            let strSubGroup = '';

            switch (jsonFromDb.subGroup) {
              case 'Employees':
                strSubGroup = EnumProductSubGroup.Employees;
                break;
              case 'Management':
                strSubGroup = EnumProductSubGroup.Management;
                break;
              case 'Corporate':
                strSubGroup = EnumProductSubGroup.Corporate;
                break;
              case 'Retail':
                strSubGroup = EnumProductSubGroup.Retail;
                break;
              case 'Wealthy':
                strSubGroup = EnumProductSubGroup.Wealthy;
                break;
              default:
                break;
            }
            if (jsonFromDb.isTemplate) {
              setPageTitle(`${jsonFromDb.name}`);
            } else {
              const projectNameFromDatabase = response.data.projectName;
              setPageTitle(
                `${projectNameFromDatabase} - ${jsonFromDb.name} - ${strSubGroup}`,
              );
              setProjectName(projectNameFromDatabase);
            }
          })
          .then(() => {
            const treeDataLocal = getSkeletonFromSurvey(
              JSON.stringify(localSurvey),
            );
            setSkeleton(treeDataLocal);
          });
      } else {
        loadSurveyFromDatabase();
        const dataChanged = JSON.parse(
          localStorage.getItem('@Victor:dataChanged') ?? '{}',
        );
        if (dataChanged) {
          if (
            // eslint-disable-next-line no-restricted-globals, no-alert
            !confirm(
              'Changes you made may not be saved. Are you sure do you want to leave?',
            )
          ) {
            // setSelectedTab('skeleton');

            return;
          }
        }

        localStorage.setItem('@Victor:dataChanged', JSON.stringify(false));
      }
      setSelectedTab(newValue);
    },
    [loadSurveyFromDatabase, surveyIdParam.surveyId],
  );

  const callbackSaveSurvey = useCallback(
    async (text: string) => {
      if (!isLocked) {
        try {
          let surveyToEncode: any = {};
          if (text !== '' && text) {
            surveyToEncode = JSON.parse(text);
          } else {
            surveyToEncode = JSON.parse(surveyCreatorText);
          }

          surveyToEncode.logo = {
            id: survey?.logo?.id,
            filePath: survey?.logo?.filePath,
          };
          surveyToEncode.startTextId = survey?.startTextId;
          surveyToEncode.finalTextId = survey?.finalTextId;
          surveyToEncode.gdprId = survey?.gdprId;
          surveyToEncode.languages = survey?.languages;
          surveyToEncode.style = survey?.style;

          // surveyToEncode.name =
          //   surveyToEncode.name ??
          //   surveyToEncode.title ??
          //   `survey-${survey?.idSurvey}`;

          let encodedSurveyToSave: any = {};
          encodedSurveyToSave = getEncodedSurvey(
            JSON.stringify(surveyToEncode),
          );

          const responsePUT = await api.put(
            `/surveys/${survey?.idSurvey}`,
            encodedSurveyToSave,
          );

          /// **TODO: melhorar tratamento de erro */
          if (responsePUT.data === null) {
            addToast({
              type: 'error',
              title: 'Survey Locked',
              description:
                'Changes not saved. This survey is locked. Please reload the page to get most recent data.',
            });
          } else {
            const refreshSurvey = survey;
            if (refreshSurvey) refreshSurvey.pages = encodedSurveyToSave.pages;
            setSurvey(refreshSurvey);
          }
          // if everything is fine with updating survey, so update all translations

          await updateSurveyTranslations(
            encodedSurveyToSave,
            Number(survey?.idSurvey),
          );
        } catch (err) {
          if (err) {
            console.log(`err`, err);
            addToast({
              type: 'error',
              title: 'Registration Error',
              description: `An error occurred while updating survey, please try again.`,
            });
          }
        }
      } else {
        addToast({
          type: 'error',
          title: 'Survey Locked',
          description: `This survey is locked. Your modificantions can not be saved.`,
        });
      }
    },
    [isLocked, survey, surveyCreatorText, addToast],
  );

  const handleGenerateSurvey = useCallback(() => {
    try {
      api.get(`/skeletons/survey/${survey?.idSurvey}`).then(async response => {
        if (response.data) {
          const surveyElements = getSurveyFromSkeleton(
            response.data?.skeletonTree,
          );

          const surveyLocal: any = survey ?? '';

          surveyLocal.pages[0].elements = surveyElements;

          const stringSurvey = JSON.stringify(surveyLocal);

          await callbackSaveSurvey(stringSurvey);

          addToast({
            type: 'success',
            title: 'Success',
            description: 'Survey generated successfully!',
          });
        }
      });
    } catch (error) {
      console.log('error:', error);
    }
  }, [addToast, callbackSaveSurvey, survey]);

  useEffect(() => {
    let mounted = true;
    let response: any = {};
    const fetchData = async (
      surveyIdParameter: number | null | undefined,
    ): Promise<void> => {
      response = await api.get(`/surveys/${surveyIdParameter}`, {
        params: {
          surveyId: surveyIdParameter,
        },
      });

      const jsonFromDb = {
        gdprId: response.data?.gdprId,
        idSurvey: response.data.surveyId,
        pages: response.data.pages,
        triggers: response.data.triggers,
        description: response.data.description,
        name: response.data.name,
        title: response.data.title,
        showTitle: response.data?.showTitle,
        isTemplate: response.data.isTemplate,
        logo: response.data.logo,
        subGroup: response.data.subGroup,
        projectId: response.data.projectId,
        productId: response.data.productId,
        startTextId: response.data.startTextId,
        finalTextId: response.data.finalTextId,
        accesses: response.data.accessess,
        style: response.data.style,
        languages: response.data.languages,
        isApproved: response.data.isApproved ?? false,
      };

      let strSubGroup = '';

      switch (jsonFromDb.subGroup) {
        case 'Employees':
          strSubGroup = EnumProductSubGroup.Employees;
          break;
        case 'Management':
          strSubGroup = EnumProductSubGroup.Management;
          break;
        case 'Corporate':
          strSubGroup = EnumProductSubGroup.Corporate;
          break;
        case 'Retail':
          strSubGroup = EnumProductSubGroup.Retail;
          break;
        case 'Wealthy':
          strSubGroup = EnumProductSubGroup.Wealthy;
          break;
        default:
          break;
      }

      const decodedSurvey = getDecodedSurvey(jsonFromDb);

      // Please, don't remove this conversion. decodedSurvey has "[[Prototype]]" field,
      // so to remove it, need to stringify it and then apply the JSON.parse
      const surveyJsonConversion = JSON.stringify(decodedSurvey);
      if (mounted) {
        if (jsonFromDb.isTemplate) {
          setPageTitle(`${jsonFromDb.name}`);
        } else {
          const projectNameFromDatabase = response.data.projectName;
          setPageTitle(
            `${projectNameFromDatabase} - ${jsonFromDb.name} - ${strSubGroup}`,
          );

          setProjectName(`${projectNameFromDatabase}`);
        }
        if (response.data.isLocked) {
          setIsLocked(response.data.isLocked);
        }
        // property responsible to define if survey is or not to be integrate with data warehouse
        setIsIntegrate(response.data.isIntegrate);
        setSurvey(JSON.parse(surveyJsonConversion));
        setSurveyCreatorText(JSON.stringify(decodedSurvey));
        if (jsonFromDb) {
          api
            .get(`/accesses/survey/${jsonFromDb.idSurvey}`, {
              params: {
                surveyId: jsonFromDb.idSurvey,
              },
            })
            .then(resp => {
              setAccesses(resp.data);
            });
        }
      }

      // await getSkeletonFromDatabase(JSON.parse(surveyJsonConversion));
    };

    fetchData(+surveyIdParam.surveyId);
    return () => {
      mounted = false;
    };
  }, [surveyIdParam.surveyId]);

  const handleChangeIsLocked = useCallback(async () => {
    try {
      await loadSurveyFromDatabase();
      api
        .put(`/surveys/${surveyIdParam.surveyId}/setLocked`, {
          isLocked: !isLocked,
        })
        .then(async response => {
          if (response) {
            setIsLocked(!isLocked);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  }, [isLocked, loadSurveyFromDatabase, surveyIdParam.surveyId]);

  const handleChangeIsReviewed = useCallback(() => {
    try {
      api
        .put(`/surveys/${surveyIdParam.surveyId}/setIntegrate`, {
          isIntegrate: !isIntegrate,
        })
        .then(async response => {
          if (response) {
            setIsIntegrate(!isIntegrate);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  }, [isIntegrate, surveyIdParam.surveyId]);

  const handleChangeIsDailyIntegration = useCallback(() => {
    try {
      api
        .put(`/surveys/${surveyIdParam.surveyId}/setDailyIntegration`, {
          isDailyIntegration: !isDailyIntegration,
        })
        .then(async response => {
          if (response) {
            setIsDailyIntegration(!isDailyIntegration);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  }, [isDailyIntegration, surveyIdParam.surveyId]);

  const updateQuestion = useCallback((element: any, questionsFromDb: any[]) => {
    let newElement: any;
    let questionIdToSearch: string;
    let alternativeId: string;
    questionIdToSearch = element?.questionId?.toString();

    // if it's an alternative, get alternative text to update
    if (questionIdToSearch.includes(`.`)) {
      const questionIdSplitted = questionIdToSearch.split('.');
      questionIdToSearch = questionIdSplitted[0];
      alternativeId = questionIdSplitted[1];

      // find the question
      const questionFromDb = questionsFromDb.find(
        (question: any) => question.questionId == questionIdToSearch,
      );

      // find it's alternative
      const alternativeFound = questionFromDb.alternatives.find(
        (alternative: any) => alternative.value == alternativeId,
      );

      const alternativeText = alternativeFound
        ? alternativeFound.text
        : questionFromDb.text;

      newElement = {
        ...element,
        choices: questionFromDb.options,
        rateValues: questionFromDb.options,
        title: `${questionFromDb.questionId} - ${alternativeText}`,
      };
      return newElement;
      // });
    }
    // check if it's an alternative

    const questionFromDb = questionsFromDb.find(
      (question: any) => question.questionId == questionIdToSearch,
    );

    newElement = {
      ...element,
      choices: questionFromDb.options,
      rateValues: questionFromDb.options,
      title: `${questionFromDb.questionId} - ${questionFromDb.text}`,
    };
    return newElement;
  }, []);

  const updateAllQuestions = useCallback(async () => {
    const newElements: any[] = [];
    const questionsFromDb = await getQuestionsWithTranslations(survey);

    survey?.pages[0]?.elements.forEach(async (element: any) => {
      if (getValidationQuestionByElementType(element.type)) {
        const result = updateQuestion(element, questionsFromDb);
        newElements.push(result);
      } else if (element.type === 'matrix') {
        let newColumns: any = [];
        const matrixRows = element?.rows?.map((row: any) => {
          const question = questionsFromDb.find(
            item => item.questionId === row.questionId,
          );
          const result = updateQuestion(question, questionsFromDb);
          newColumns = result.choices;
          const newRow = {
            ...row,
            text: result.text,
          };

          return newRow;
        });
        const newElement = {
          ...element,
          rows: matrixRows,
          columns: newColumns,
        };
        newElements.push(newElement);
      } else {
        newElements.push(element);
      }
    });

    const surveyPageToSave = { ...survey?.pages[0], elements: newElements };
    const surveyToSave = { ...survey, pages: [surveyPageToSave] };
    await callbackSaveSurvey(JSON.stringify(surveyToSave));
    await loadSurveyFromDatabase();
  }, [callbackSaveSurvey, loadSurveyFromDatabase, survey, updateQuestion]);

  return (
    <Container>
      {selectedTab === 'surveyCreator' && (
        <div style={{ marginTop: 10, marginBottom: 25 }}>
          <header>
            <TitleLockedIcon>
              <h1>{pageTitle}</h1>
            </TitleLockedIcon>

            <ButtonLock>
              {isLocked && <Lock></Lock>}
              {!isLocked && <LockOpen></LockOpen>}
              <Switch
                ref={switchRef}
                checked={isLocked}
                onChange={handleChangeIsLocked}
                color="primary"
              />
            </ButtonLock>
          </header>
          <Link
            to={{
              pathname: `/questions/`,
              state: {
                select: true,
                surveyId: survey?.idSurvey,
                pageTitle,
              },
            }}
          >
            {!isLocked && (
              <Button marginRight="30px" width="150px" height="40px">
                Add Questions
              </Button>
            )}
          </Link>
          <Link
            to={{
              pathname: `/surveys/${survey?.idSurvey}/accesses`,
              state: {
                surveyName: survey?.name,
                subgroup:
                  Object.values(EnumProductSubGroup)[
                    Object.keys(EnumProductSubGroup).indexOf(
                      survey?.subGroup ? survey?.subGroup : '',
                    )
                  ],

                pageTitle,
                isTemplate: survey?.isTemplate,
                languages: survey?.languages,
              },
            }}
          >
            <Button marginRight="35px" width="130px" height="40px">
              Links
            </Button>
          </Link>
          {!survey?.isTemplate && (
            <>
              <Link
                to={{
                  pathname: `/surveyTexts/type/start`,
                  state: {
                    select: true,
                    surveyId: surveyIdParam && surveyIdParam?.surveyId,
                    survey: survey && survey,
                    selectedStartTextId: survey?.startTextId,
                    pageTitle,
                  },
                }}
              >
                <Button marginRight="35px" width="130px" height="40px">
                  Start Text
                </Button>
              </Link>

              <Link
                to={{
                  pathname: `/surveyTexts/type/final`,
                  state: {
                    select: true,
                    surveyId: survey?.idSurvey,
                    survey: survey && survey,
                    selectedFinalTextId: survey?.finalTextId,
                    pageTitle,
                  },
                }}
              >
                <Button marginRight="35px" width="130px" height="40px">
                  Final Text
                </Button>
              </Link>

              <Link
                to={{
                  pathname: `/gdpr/`,
                  state: {
                    select: true,
                    surveyId: survey?.idSurvey,
                    survey: survey && survey,
                  },
                }}
              >
                <Button marginRight="35px" width="130px" height="40px">
                  GDPR
                </Button>
              </Link>

              <Link
                to={{
                  pathname: `/logos`,
                  state: {
                    select: true,
                    surveyId: surveyIdParam && surveyIdParam?.surveyId,
                    survey: survey && survey,
                    logoId: survey?.logo?.id?.toString(),
                    pageTitle,
                  },
                }}
              >
                <Button marginRight="35px" width="100px" height="40px">
                  Logo
                </Button>
              </Link>

              <Link
                to={{
                  pathname: '/style',
                  state: {
                    surveyId: surveyIdParam && surveyIdParam?.surveyId,
                    survey: survey && survey,
                    pageTitle,
                  },
                }}
              >
                <Button marginRight="35px" width="150px" height="40px">
                  Style
                </Button>
              </Link>

              <Link
                to={{
                  pathname: `/surveys/${survey?.idSurvey}/surveyTranslations`,
                  state: {
                    surveyId: surveyIdParam && surveyIdParam?.surveyId,
                    survey: survey && survey,
                    isLocked,
                  },
                }}
              >
                <Button marginRight="35px" width="150px" height="40px">
                  Translations
                </Button>
              </Link>

              <Link
                to={{
                  pathname: `/surveys/${survey?.idSurvey}/export`,
                  state: {
                    pageTitle,
                  },
                }}
              >
                <Button marginRight="35px" width="150px" height="40px">
                  Export Results
                </Button>
              </Link>

              <Link
                to={{
                  pathname: `/exportDataWarehouse`,
                  state: {
                    idSurvey: survey?.idSurvey,
                  },
                }}
              >
                <Button marginRight="35px" width="210px" height="40px">
                  Export DataWarehouse
                </Button>
              </Link>

              <Link
                to={{
                  pathname: `/ExportToPdf`,
                  state: {
                    surveyCreatorText,
                  },
                }}
              >
                <Button marginRight="35px" width="130px" height="40px">
                  Export PDF
                </Button>
              </Link>

              {/* <Link to={`/surveys/${survey?.idSurvey}/analytics`}>
                <Button marginRight="35px" width="150px" height="40px">
                  Analytics
                </Button>
              </Link> */}

              <Link
                to={{
                  pathname: `/surveys/${survey?.idSurvey}/answers`,
                  state: {
                    isReviewed: survey?.isApproved ?? false,
                    userGroup: survey?.subGroup,
                    projectName,
                    pageTitle,
                  },
                }}
              >
                <Button marginRight="35px" width="150px" height="40px">
                  Open Answers
                </Button>
              </Link>
            </>
          )}

          <Link
            to={{
              pathname: `/surveys/${survey?.idSurvey}/runAnalyser`,
              state: {
                surveyName: survey?.name,
                idSurvey: survey?.idSurvey,
                projectId: survey?.projectId,
                userGroup: survey?.subGroup,
                projectName,
              },
            }}
          >
            <Button marginRight="35px" width="150px" height="40px">
              Run Analyser
            </Button>
          </Link>

          <CheckIntegrate>
            <Switch
              ref={switchIntegrateRef}
              checked={isIntegrate}
              onChange={handleChangeIsReviewed}
              color="primary"
            />
            {'Integrate with data warehouse'}
          </CheckIntegrate>

          <CheckIntegrate>
            <Switch
              ref={switchDailyIntegrationRef}
              checked={isDailyIntegration}
              onChange={handleChangeIsDailyIntegration}
              color="primary"
            />
            {'Daily Integration with data warehouse'}
          </CheckIntegrate>

          {survey?.isTemplate && (
            <Link
              to={`/products/surveyTemplate/${survey.idSurvey}`}
              target="_blank"
            >
              <Button
                type="button"
                marginRight="35px"
                width="200px"
                height="40px"
              >
                View Demo
              </Button>
            </Link>
          )}
        </div>
      )}
      {selectedTab === 'skeleton' && (
        <div style={{ marginTop: 10, marginBottom: 33 }}>
          <header>
            <TitleLockedIcon>
              <h1>{pageTitle}</h1>
            </TitleLockedIcon>

            <ButtonLock>
              {isLocked && <Lock></Lock>}
              {!isLocked && <LockOpen></LockOpen>}
              {/* <Switch
                ref={switchRef}
                checked={isLocked}
                onChange={handleChangeIsLocked}
                color="primary"
              /> */}
            </ButtonLock>
          </header>
          <br />
          <br />
        </div>
      )}

      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleSetSelectedTab}
      >
        <Tab value="surveyCreator" label="Survey Creator" />
        <Tab value="skeleton" label="Skeleton" />
      </Tabs>
      {selectedTab === 'skeleton' && (
        <>
          <SpinnerThreeDots loading={true}>
            <SortTree
              surveyId={parseInt(surveyIdParam.surveyId, 10)}
              // surveyLanguages={survey?.languages}
              setSkeleton={setSkeleton}
              treeDataParam={skeleton && skeleton?.skeletonTree}
              handleGenerateSurvey={handleGenerateSurvey}
              surveyIslocked={isLocked}
              surveyLanguages={survey?.languages}
            ></SortTree>
          </SpinnerThreeDots>
        </>
      )}
      {selectedTab === 'surveyCreator' && (
        <SpinnerThreeDots loading={true}>
          <SurveyProvider
            surveyText={surveyCreatorText}
            callbackSaveSurvey={callbackSaveSurvey}
            updateAllQuestions={updateAllQuestions}
            accesses={accesses && accesses}
            idSurvey={survey?.idSurvey}
            isLocked={isLocked}
          >
            <SurveyCreatorRenderComponent />
          </SurveyProvider>
        </SpinnerThreeDots>
      )}
    </Container>
  );
};

export default SurveyPageEdit;
